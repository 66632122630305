<template>
  <div class="page-header-index-wide page-header-wrapper-grid-content-main">
    <a-row :gutter="24">
      <a-col :md="24" :lg="24">
        <a-card style="width: 100%" :bordered="false" class="top-container">
          <a-row>
            <!-- <a-col :md="3" :lg="3">
              <div class="">
                <div class="avatar">
                  <img :src="getAvatar()" style="width: 100%" />
                </div>
              </div>
            </a-col> -->
            <a-col :md="16" :lg="16">
              <info-item :labelWidth="labelWidth" label="昵称">{{ userInfo.nickName }}</info-item>
              <info-item :labelWidth="labelWidth" label="邮箱">{{ userInfo.email }}</info-item>
              <info-item :labelWidth="labelWidth" label="所属部门">
                <a-tag v-for="one in userInfo.departs" :key="one.id">
                  {{ one.departName }}
                </a-tag>
              </info-item>
              <info-item :labelWidth="labelWidth" label="职位">
                <a-tag v-for="one in userInfo.positions" :key="one.id">
                  {{ one.name }}
                </a-tag>
              </info-item>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>

    <a-row :gutter="24">
      <a-col :md="24" :lg="24">
        <a-card
          v-show="isDesktop()"
          style="width: 100%"
          :bordered="false"
          :tabList="tabListNoTitle"
          :activeTabKey="noTitleKey"
          @tabChange="key => handleTabChange(key, 'noTitleKey')"
        >
          <!-- <task-page v-if="noTitleKey === 'task'"></task-page> -->
          <rating-page v-if="noTitleKey === 'rating'"></rating-page>
          <settlement-page v-else-if="noTitleKey === 'settlement'"></settlement-page>
          <user-file-page
            @changeUserTab="changeUserTab"
            ref="userForm"
            @upImg="upImg"
            @openCropper="openCropper"
            v-else-if="noTitleKey === 'user-file'"
          ></user-file-page>
        </a-card>
        <!-- <task-page v-show="!isDesktop()"></task-page> -->
      </a-col>
    </a-row>
    <!-- 登陆成功后弹框 -->
    <a-modal
      :keyboard="false"
      class="login-user"
      :mask-closable="false"
      :closable="false"
      v-model="userModalVisible"
      width="700px"
    >
      <div slot="title">
        填写个人信息：
        <span style="color:red;font-size:12px;"
          >最迟需在第一次登录系统后的次日24点前填写完毕，否则无法正常进入系统。</span
        >
      </div>
      <login-user-modal
        @changeTabs="changeTabs"
        @changeTab="changeTab"
        @openCropper="openCropper"
        :tab="tab"
        ref="userForm"
        @closeUserModal="closeUserModal"
        :userInfo="userInfo"
      ></login-user-modal>
      <template #footer>
        <a-button key="back" @click="closeUserModal" v-if="tab == '2'">
          关闭
        </a-button>
        <a-button key="submit" type="primary" :loading="btnLoading" @click="handleUserInfo">
          {{ tab == '1' ? '下一步' : '保存' }}
        </a-button>
      </template>
    </a-modal>

    <!-- 先把新用户登录后要看人员须知那些隐藏掉吧，一上来就填信息就行 -->
    <!-- <a-modal
      :keyboard="false"
      :mask-closable="false"
      :closable="false"
      v-model="memberNoticeVisible"
      title="阅读人员须知"
      width="80%"
    >
      <member-notice ref="memberNotice" is-preview @allTableConfirm="allTableConfirm"></member-notice>
      <template #footer>
        <a-tooltip title="阅读完所有人员须知后方可确认">
          <a-button
            :disabled="confirmBtnDisable"
            key="submit"
            type="primary"
            :loading="btnLoading"
            @click="handleMemberNotice"
          >
            确认
          </a-button>
        </a-tooltip>
      </template>
    </a-modal> -->

    <!-- 有新消息弹框 -->
    <user-cropper-model ref="userCropperModel" @upImg="upImg"> </user-cropper-model>
    <a-modal
      :keyboard="false"
      :mask-closable="false"
      :closable="false"
      v-model="workVisible"
      title="请选择可工作日"
      width="35%"
    >
      <a-form-model :model="formData.userFile" ref="workForm" :rules="validatorRules" v-bind="layout">
        <a-form-model-item prop="workDays" label="通常每周可工作日">
          <a-select
            mode="multiple"
            placeholder="通常每周可工作日"
            v-model="formData.userFile.workDays"
            @change="workDaysChange"
          >
            <a-select-option :value="1" :disabled="selectDis">周一</a-select-option>
            <a-select-option :value="2" :disabled="selectDis">周二</a-select-option>
            <a-select-option :value="3" :disabled="selectDis">周三</a-select-option>
            <a-select-option :value="4" :disabled="selectDis">周四</a-select-option>
            <a-select-option :value="5" :disabled="selectDis">周五</a-select-option>
            <a-select-option :value="6" :disabled="selectDis">周六</a-select-option>
            <a-select-option :value="7" :disabled="selectDis">周日</a-select-option>
            <a-select-option :value="-1">跳过中国法定节假日</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="workdayAvailableCount" label="工作日每天可安排数量">
          <a-input-number :min="0" v-model="formData.userFile.workdayAvailableCount" @change="workCountChange" />
        </a-form-model-item>
      </a-form-model>
      <template #footer>
        <a-button key="submit" type="primary" @click="saveWork"> 保存 </a-button>
      </template>
    </a-modal>
    <a-modal
      :keyboard="false"
      :mask-closable="false"
      :closable="false"
      v-model="selfInfoShow"
      title="个人信息"
      width="35%"
    >
      <a-form-model :model="formData.userFile" ref="selfForm" :rules="validatorSelfRules" v-bind="layout">
        <div style="position: relative;height:20px;padding-bottom: 42px;">
          <span style="  position: absolute; ;font-size:12px;top:0;color:#aca9a9"
            >下列信息仅BOSS及HR有权查看，请如实填写。</span
          >
        </div>
        <a-form-model-item label="姓名" prop="userName">
          <a-input placeholder="请输入姓名" v-model="formData.userFile.userName" />
        </a-form-model-item>
        <a-form-model-item label="性别" prop="sex">
          <a-radio-group v-model="formData.userFile.sex">
            <a-radio :value="1">男</a-radio>
            <a-radio :value="0">女</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="出生日期" prop="bornDate">
          <a-date-picker format="YYYY-MM-DD" v-model="formData.userFile.bornDate" />
        </a-form-model-item>
        <a-form-model-item label="国籍" prop="country">
          <a-select placeholder="国籍" v-model="formData.userFile.country">
            <a-select-option value="中国">中国</a-select-option>
            <a-select-option value="美国">美国</a-select-option>
            <a-select-option value="英国">英国</a-select-option>
            <a-select-option value="日本">日本</a-select-option>
            <a-select-option value="韩国">韩国</a-select-option>
          </a-select>
        </a-form-model-item>
<!--         <a-form-model-item v-if="formData.userFile.country == '中国'" prop="nation" label="民族">
          <a-auto-complete placeholder="民族" v-model="formData.userFile.nation">
            <template slot="dataSource">
              <a-select-option v-for="n in nationList" :key="n">{{ n }}</a-select-option>
            </template>
          </a-auto-complete>
        </a-form-model-item> -->
        <a-form-model-item label="电话号码" prop="phone">
          <a-input placeholder="请输入电话号码" v-model="formData.userFile.phone" />
        </a-form-model-item>
<!--         <a-form-model-item label="学历" prop="education">
          <a-select placeholder="学历" v-model="formData.userFile.education">
            <a-select-option value="博士">博士</a-select-option>
            <a-select-option value="硕士">硕士</a-select-option>
            <a-select-option value="本科">本科</a-select-option>
            <a-select-option value="专科">专科</a-select-option>
            <a-select-option value="高中">高中</a-select-option>
            <a-select-option value="初中">初中</a-select-option>
            <a-select-option value="小学">小学</a-select-option>
          </a-select>
        </a-form-model-item> -->
<!--         <a-form-model-item label="毕业院校" prop="school">
          <a-input placeholder="请输入毕业院校" v-model="formData.userFile.school" />
        </a-form-model-item> -->
<!--         <a-form-model-item label="职位相关证书" prop="certificate">
          <a-input placeholder="请输入职位相关证书" v-model="formData.userFile.certificate" />
        </a-form-model-item> -->
<!--         <a-form-model-item label="工作经历" prop="workExperience">
          <a-textarea
            autoSize
            placeholder="请输入和现在职位相关的工作经历"
            v-model="formData.userFile.workExperience"
          />
        </a-form-model-item> -->
<!--         <template
          v-if="
            (country == 'qt' || country == 'gat') &&
              formData.userFile.contractType != '意向全职' &&
              formData.userFile.contractType != '兼职'
          "
        >
          <div style="padding-top: 10px;padding-bottom: 15px;">
            <span style="display:inline-block;width:160px;font-weight:bold;font-size:14px;">英文银行账户信息</span>
            <span style="font-size:12px;color: rgba(0,0,0,0.25);">以下内容除收款账号，其余内容请输入英文信息</span>
          </div>
          <a-form-model-item label="户名" prop="paymentName">
            <a-input placeholder="请输入户名" v-model="formData.userFile.paymentName" />
          </a-form-model-item>
          <a-form-model-item label="收款账号" prop="alipayOtherAccount">
            <a-input placeholder="请输入收款账号" v-model="formData.userFile.bankAccount" />
          </a-form-model-item>
          <a-form-model-item label="银行名称" prop="bankName">
            <a-input placeholder="请输入银行名称" v-model="formData.userFile.bankName" />
          </a-form-model-item>
          <a-form-model-item label="支行名称" prop="branchBank">
            <a-input placeholder="请输入支行名称" v-model="formData.userFile.branchBank" />
          </a-form-model-item>
          <a-form-model-item label="SWIFT CODE" prop="paymentBank">
            <a-input placeholder="请输入SWIFT CODE" v-model="formData.userFile.paymentBank" />
          </a-form-model-item>
          <a-form-model-item label="银行地址" prop="bankAddress">
            <a-input placeholder="请输入银行地址" v-model="formData.userFile.bankAddress" />
          </a-form-model-item>
          <a-form-model-item label="个人地址">
            <a-input placeholder="请输入个人地址" v-model="formData.userFile.personalAddress" />
          </a-form-model-item>
        </template>
        <template v-else>
          <div>
            <a-form-model-item
              label="收款账号"
              :prop="
                country != 'qt' &&
                country != 'gat' &&
                formData.userFile.contractType != '意向全职' &&
                formData.userFile.contractType != '兼职'
                  ? 'alipayAccount'
                  : 'alipayOtherAccount'
              "
            >
              <a-select
                placeholder="请选择收款方式"
                v-if="formData.userFile.contractType == '意向全职' || formData.userFile.contractType == '兼职'"
                @change="paymentTypeChange"
                :default-value="formData.userFile.paymentType"
              >
                <a-select-option value="EN_ACCOUNT">
                  <a-icon type="bank" style="width:1vw;position: relative;left: -3px" />
                  全英账户</a-select-option
                >
                <a-select-option value="ALIPAY">
                  <a-icon type="alipay-circle" style="width:1vw;position: relative;left: -3px;" />
                  支付宝</a-select-option
                >
                <a-select-option value="WECHAT">
                  <a-icon type="wechat" style="width:1vw;position: relative;left: -3px;" />
                  微信</a-select-option
                >
                <a-select-option
                  value="BANK"
                  v-if="
                    formData.userFile &&
                      formData.userFile.workCity &&
                      typeof formData.userFile.workCity === 'object' &&
                      formData.userFile.workCity.some(item => item == 83)
                  "
                >
                  <a-icon type="credit-card" style="width:1vw;position: relative;left: -3px;" />
                  银行账户</a-select-option
                >
              </a-select>
              <a-input
                v-if="
                  country != 'qt' &&
                    country != 'gat' &&
                    formData.userFile.contractType != '意向全职' &&
                    formData.userFile.contractType != '兼职'
                "
                :defaultValue="formData.userFile.alipayAccount"
                placeholder="请输入招行卡号"
                @blur="alipayAccountBlur"
              >
                <img
                  slot="prefix"
                  src="./../../../assets/zh.png"
                  alt=""
                  style="width:1vw;position: relative;left: -4px;"
                />
              </a-input>
              <a-input
                v-if="formData.userFile.paymentType == 'ALIPAY'"
                placeholder="请输入支付宝账号"
                v-model="formData.userFile.alipayOtherAccount"
              >
              </a-input>
              <a-input
                v-if="formData.userFile.paymentType == 'WECHAT'"
                placeholder="请输入微信账号"
                v-model="formData.userFile.alipayOtherAccount"
              >
              </a-input>
              <template
                v-if="
                  formData.userFile.paymentType == 'BANK' &&
                    formData.userFile.contractType != '全职' &&
                    formData.userFile.contractType != '实习'
                "
              >
                <a-select
                  show-search
                  placeholder="请选择开户行"
                  option-filter-prop="label"
                  v-if="country != 'qt' && country != 'gat'"
                  v-model="formData.userFile.bankName"
                >
                  <a-select-option :label="item.text" v-for="item in bankList" :key="item.value">{{
                    item.text
                  }}</a-select-option>
                </a-select>
                <a-input
                  v-if="country != 'qt' && country != 'gat'"
                  placeholder="请输入银行卡号"
                  @blur="submitBank"
                  v-model="formData.userFile.bankAccount"
                />
                <a-input
                  v-if="country != 'qt' && country != 'gat'"
                  placeholder="请输入户名"
                  v-model="formData.userFile.paymentName"
                />
              </template>
            </a-form-model-item>
            <template v-if="formData.userFile.paymentType == 'EN_ACCOUNT'">
              <div style="padding-top: 10px;padding-bottom: 15px;">
                <span style="display:inline-block;width:160px;font-weight:bold;font-size:14px;">英文银行账户信息</span>
                <span style="font-size:12px;color: rgba(0,0,0,0.25);">以下内容除收款账号，其余内容请输入英文信息</span>
              </div>
              <a-form-model-item label="户名">
                <a-input placeholder="请输入户名" v-model="formData.userFile.paymentName" />
              </a-form-model-item>
              <a-form-model-item label="收款账号" prop="alipayOtherAccount">
                <a-input placeholder="请输入收款账号" v-model="formData.userFile.bankAccount" />
              </a-form-model-item>
              <a-form-model-item label="银行名称">
                <a-input placeholder="请输入银行名称" v-model="formData.userFile.bankName" />
              </a-form-model-item>
              <a-form-model-item label="支行名称">
                <a-input placeholder="请输入支行名称" v-model="formData.userFile.branchBank" />
              </a-form-model-item>
              <a-form-model-item label="SWIFT CODE">
                <a-input placeholder="请输入SWIFT CODE" v-model="formData.userFile.paymentBank" />
              </a-form-model-item>
              <a-form-model-item label="银行地址">
                <a-input placeholder="请输入银行地址" v-model="formData.userFile.bankAddress" />
              </a-form-model-item>
              <a-form-model-item label="个人地址">
                <a-input placeholder="请输入个人地址" v-model="formData.userFile.personalAddress" />
              </a-form-model-item>
            </template>
          </div>
        </template>
        <template
          v-if="
            formData.userFile.paymentType == 'BANK' &&
              formData.userFile.contractType != '全职' &&
              formData.userFile.contractType != '实习' &&
              country == 'qt'
          "
        >
          <a-form-model-item label="名前片假名">
            <a-input placeholder="请输入名前片假名" v-model="formData.userFile.paymentName" />
          </a-form-model-item>
          <a-form-model-item label="銀行名">
            <a-input placeholder="请输入銀行名" v-model="formData.userFile.bankName" />
          </a-form-model-item>
          <a-form-model-item label="店番号">
            <a-input placeholder="请输入店番号" v-model="formData.userFile.bankAccount" />
          </a-form-model-item>
          <a-form-model-item label="支店名">
            <a-input placeholder="请输入支店名" v-model="formData.userFile.branchBank" />
          </a-form-model-item>
          <a-form-model-item label="口座番号">
            <a-input placeholder="请输入口座番号" v-model="formData.userFile.bankAddress" />
          </a-form-model-item>
          <a-form-model-item label="預金種別">
            <a-input placeholder="请输入預金種別" v-model="formData.userFile.personalAddress" />
          </a-form-model-item>
        </template> -->
<!--         <span style="color:red;font-size:12px;position:relative;top:-3px;"
          >*本人承诺以上所填信息真实有效，若有虚假，本人自愿承担带来的经济损失</span
        > -->
      </a-form-model>
      <template #footer>
        <a-button key="submit" type="primary" @click="saveSelf"> 保存 </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import PageLayout from '@/components/page/PageLayout'
import RouteView from '@/components/layouts/RouteView'
import { UserFilePage, TaskPage, RatingPage, SettlementPage } from './page'
import { mapGetters } from 'vuex'
import { mixinDevice } from '@/utils/mixin.js'
import { getFileAccessHttpUrl } from '@/api/manage'
import LoginUserModal from '@/views/dashboard/loginUserModal'
import MemberNotice from '@/views/system/MemberNotice'
import { postAction, getAction, putAction } from '@/api/manage'
import userCropperModel from './cropper-model.vue'
import { citys } from './page/city.js'
import axios from 'axios'
export default {
  components: {
    RouteView,
    PageLayout,
    TaskPage,
    UserFilePage,
    RatingPage,
    LoginUserModal,
    MemberNotice,
    userCropperModel
  },
  mixins: [mixinDevice],
  data() {
    let validatePay = (rule, value, callback) => {
      if (!this.formData.alipayOtherAccount) {
        callback(new Error('请填写收款账号'))
      } else {
        callback()
      }
    }
    return {
      btnLoading: false,
      userModalVisible: false,
      memberNoticeVisible: false,
      workVisible: false,
      selfInfoShow: false,
      labelWidth: '70',
      formData: { userFile: {} },
      userInfo: {
        nickName: '昵称',
        email: '',
        departs: [],
        positions: [],
        roles: [],
        userFile: {}
      },
      layout: {
        labelCol: {
          style: 'width: 160px',
          span: 7
        },
        wrapperCol: {
          span: 17
        }
      },
      tabListNoTitle: [
        {
          key: 'task',
          tab: '最近任务'
        },
        /*         {
          key: 'rating',
          tab: '评级'
        },
        {
          key: 'settlement',
          tab: '结算'
        }, */
        {
          key: 'user-file',
          tab: '个人信息'
        }
      ],
      selectDis: false,
      validatorRules: {
        workdayAvailableCount: [{ required: true, message: '请输入工作日每天可安排数量', trigger: 'blur' }],
        workDays: [{ required: true, message: '请选择每周可工作日', trigger: 'blur' }]
      },
      nationList: [
        '汉族',
        '满族',
        '蒙古族',
        '回族',
        '藏族',
        '维吾尔族',
        '苗族',
        '彝族',
        '壮族',
        '布依族',
        '侗族',
        '瑶族',
        '白族',
        '土家族',
        '哈尼族',
        '哈萨克族',
        '傣族',
        '黎族',
        '傈僳族',
        '佤族',
        '畲族',
        '高山族',
        '拉祜族',
        '水族',
        '东乡族',
        '纳西族',
        '景颇族',
        '柯尔克孜族',
        '土族',
        '达斡尔族',
        '仫佬族',
        '羌族',
        '布朗族',
        '撒拉族',
        '毛南族',
        '仡佬族',
        '锡伯族',
        '阿昌族',
        '普米族',
        '朝鲜族',
        '塔吉克族',
        '怒族',
        '乌孜别克族',
        '俄罗斯族',
        '鄂温克族',
        '德昂族',
        '保安族',
        '裕固族',
        '京族',
        '塔塔尔族',
        '独龙族',
        '鄂伦春族',
        '赫哲族',
        '门巴族',
        '珞巴族',
        '基诺族'
      ],
      validatorSelfRules: {
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        bornDate: [{ required: true, message: '请选择出生日期', trigger: 'change' }],
        country: [{ required: true, message: '请选择国籍', trigger: 'blur' }],
        nation: [{ required: true, message: '请选择民族', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入电话号码', trigger: 'blur' }],
        education: [{ required: true, message: '请选择学历', trigger: 'blur' }],
        school: [{ required: true, message: '请选择毕业院校', trigger: 'blur' }],
        certificate: [{ required: true, message: '请输入职业相关证书', trigger: 'blur' }],
        workExperience: [{ required: true, message: '请输入相关工作经历', trigger: 'blur' }],
        bankAccount: [{ required: true, message: '请输入收款账号', trigger: 'blur' }],
        paymentName: [{ required: true, message: '请输入英文户名', trigger: 'blur' }],
        bankName: [{ required: true, message: '请输入银行名称', trigger: 'blur' }],
        branchBank: [{ required: true, message: '请输入支行名称', trigger: 'blur' }],
        paymentBank: [{ required: true, message: '请输入SWIFT CODE', trigger: 'blur' }],
        bankAddress: [{ required: true, message: '请输入银行地址', trigger: 'blur' }],
        personalAddress: [{ required: true, message: '请输入个人地址', trigger: 'blur' }],
        alipayAccount: [
          {
            required: true,
            message: '银行卡号格式错误，请重新输入',
            trigger: 'blur',
            pattern: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/
          }
        ],
        alipayOtherAccount: [{ validator:validatePay,  trigger: 'change' }]
      },
      noTitleKey: 'task',
      confirmBtnDisable: true,
      country: '',
      tab: '1',
      bankList: []
    }
  },
  mounted() {},
  created() {
    const userInfo = Vue.ls.get(USER_INFO)
    this.userInfo = Object.assign({}, userInfo)
    this.bankList = citys.bankList
    console.log({ 'this.userInfo': this.userInfo })
    let { id, userFile } = userInfo
    if (!userFile.workDays) userFile.workDays = []
    this.formData = {
      ...this.formData,
      id,
      userFile
    }
    this.userModalVisible = this.userInfo.userFile.needUserGuide
    if (!this.userInfo.userFile.needUserGuide) {
      this.workVisible = this.userInfo.userFile.needWorkDays
      if (
        this.userInfo.roles.some(item => {
          return (
            item.roleCode == 'admin' || item.roleCode == 'boss' || item.roleCode == 'tongchou' || item.roleCode == 'hr'
          )
        })
      ) {
        this.workVisible = false
      }
    }
    if (this.formData.userFile) {
      if (this.formData.userFile.workCity && typeof this.formData.userFile.workCity != 'object') {
        this.formData.userFile.workCity = this.formData.userFile.workCity.split(',')
        this.country = this.formData.userFile.workCity[0]
      }
    }
    this.getHoliday()
  },
  methods: {
    ...mapGetters(['nickname', 'avatar']),
    alipayAccountBlur(e) {
      this.formData.userFile.alipayAccount = e.target.value.replace(/\s*/g, '')
    },
    submitBank() {
      let str = this.formData.userFile.bankAccount.replace(/\s*/g, '')
      axios
        .get(
          `https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardNo=${str}&cardBinCheck=true`
        )
        .then(res => {
          const { bank, cardType, validated, messages } = res.data
          if (validated) {
            this.formData.userFile.bankName = bank
          }
        })
    },
    paymentTypeChange(value) {
      this.formData.userFile.paymentType = value
      this.$forceUpdate()
    },
    async getHoliday() {
      const res = await getAction('/tasks/holiday')
      if (res.code == 428) {
        //个人信息48小时弹窗
        this.selfInfoShow = true
      }
    },
    changeUserTab() {
      this.noTitleKey = 'settlement'
      this.$nextTick(() => {
        this.noTitleKey = 'user-file'
      })
    },
    workCountChange() {
      if (
        this.formData.userFile.workDays.some(item => {
          return item == 8
        })
      ) {
        this.selectDis = true
        this.formData.userFile.workDays = [8]
      } else {
        this.selectDis = false
      }
      let n1 = this.formData.userFile.workdayAvailableCount || 0
      if (this.formData.userFile.weekendAvailableCount !== undefined) {
        if (
          this.formData.userFile.workDays.every(item => {
            return item != 6 && item != 7 && item != 8
          })
        ) {
          this.formData.userFile.weekendAvailableCount = 0
        }
      }
      let n2 = this.formData.userFile.weekendAvailableCount || 0
      let arr = this.formData.userFile.workDays?.filter(item => {
        return item != 8
      })
      let length = arr ? arr.length : 0
      this.formData.userFile.weekAvailableCount = n1 * length + n2
      console.log(this.formData.userFile.weekAvailableCount)
      // 月可安排 this.formData.userFile.monthAvailableCount = this.formData.userFile.weekAvailableCount * 4
    },
    workDaysChange() {
      if (
        this.formData.userFile.workDays.some(item => {
          return item == -1
        })
      ) {
        this.selectDis = true
        this.formData.userFile.workDays = [-1]
      } else {
        this.selectDis = false
      }
      let n1 = this.formData.userFile.workdayAvailableCount || 0
      if (this.formData.userFile.weekendAvailableCount !== undefined) {
        if (
          this.formData.userFile.workDays.every(item => {
            return item != 6 && item != 7 && item != -1
          })
        ) {
          this.formData.userFile.weekendAvailableCount = 0
        } else {
        }
      }
      let n2 = this.formData.userFile.weekendAvailableCount || 0
      let arr = this.formData.userFile.workDays?.filter(item => {
        return item != 8
      })
      let length = arr ? arr.length : 0
      this.formData.userFile.weekAvailableCount = n1 * length + n2
      console.log(this.formData.userFile.weekAvailableCount)
      /* this.formData.userFile.monthAvailableCount = this.formData.userFile.weekAvailableCount * 4 月可安排 */
    },
    saveWork() {
      this.$refs['workForm'].validate(async valid => {
        if (valid) {
          await putAction('/sys/user/edit_user_file', {
            userFile: {
              workdayAvailableCount: this.formData.userFile.workdayAvailableCount,
              workDays: this.formData.userFile.workDays,
              id: this.formData.userFile.id,
              userId: this.formData.userFile.userId,
              needWorkDays: false
            },
            id: this.formData.id
          })
          try {
            this.$message.success('保存成功')
            window.location.reload()
            this.workVisible = false
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    saveSelf() {
      if (this.formData.userFile.paymentType == 'EN_ACCOUNT') {
        this.formData.alipayOtherAccount = this.formData.userFile.bankAccount||this.formData.userFile.alipayOtherAccount
        
      }
      if(this.formData.userFile.alipayOtherAccount){
        this.formData.alipayOtherAccount = this.formData.userFile.alipayOtherAccount
      }
      if(this.formData.userFile.bankAccount){
        this.formData.alipayOtherAccount = this.formData.userFile.bankAccount
      }
      this.$refs['selfForm'].validate(async valid => {
        if (valid) {
          if (
            this.country != 'qt' &&
            this.country != 'gat' &&
            this.formData.userFile.contractType != '意向全职' &&
            this.formData.userFile.contractType != '兼职'
          ) {
            if (this.formData.userFile.alipayAccount) {
              this.formData.userFile.alipayAccount = this.formData.userFile.alipayAccount.replace(/\s*/g, '')
            }
          } else {
            this.formData.userFile.alipayAccount = this.formData.userFile.alipayOtherAccount
          }
          delete this.formData.alipayOtherAccount
          delete this.formData.userFile.alipayOtherAccount
          await putAction('/sys/user/edit_user_file', this.formData)
          try {
            this.$message.success('保存成功')
            await putAction('/sys/user/changeWorkInfoTimer', {})
            window.location.reload()
            this.selfInfoShow = false
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    upImg(file) {
      this.$refs.userForm.upImg(file)
    },
    openCropper(url) {
      this.$refs.userCropperModel.openModal(url)
    },
    getAvatar() {
      return this.userInfo.userFile.avatar
      // return getFileAccessHttpUrl(this.avatar())
    },
    async changeTab(isTab) {
      this.btnLoading = true
      setTimeout(() => (this.btnLoading = false), 2000)
      this.$refs.userForm.saveForm(() => {
        // 先把新用户登录后要看人员须知那些隐藏掉吧，一上来就填信息就行
        putAction('/sys/user/changeNeedUserGuideFalse').then(async ({ success }) => {
          if (success) {
            // this.$message.success('您已完成新手指引')
            this.userInfo.userFile.needUserGuide = false
            Vue.ls.set(USER_INFO, this.userInfo, 7 * 24 * 60 * 60 * 1000)
          } else {
            // this.$message.error('新手指引完成失败了，请联系管理员')
          }
        })
      }, true)
      /*       if (isTab) {
        await putAction('/sys/user/changeWorkInfoTimer', { workInfoTimer: this.getCurrentTime() })
      } else {
        await putAction('/sys/user/changeWorkInfoTimer', {})
      } */
    },
    changeTabs(key) {
      this.tab = key
    },
    handleTabChange(key, type) {
      this[type] = key
    },
    async closeUserModal() {
      this.userModalVisible = false
      /* this.memberNoticeVisible = true */
      this.btnLoading = false
      this.workVisible = this.userInfo.userFile.needWorkDays
      await putAction('/sys/user/changeWorkInfoTimer', { workInfoTimer: this.getCurrentTime() })
      if (!this.userInfo.userFile.needWorkDays) {
        window.location.reload()
      }
    },
    getCurrentTime() {
      var date = new Date() //当前时间
      var year = date.getFullYear() //返回指定日期的年份
      var month = repair(date.getMonth() + 1) //月
      var day = repair(date.getDate()) //日
      var hour = repair(date.getHours()) //时
      var minute = repair(date.getMinutes()) //分
      var second = repair(date.getSeconds()) //秒
      function repair(i) {
        if (i >= 0 && i <= 9) {
          return '0' + i
        } else {
          return i
        }
      }
      //当前时间
      var curTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
      return curTime
    },
    async handleUserInfo() {
      this.btnLoading = true
      if (this.tab == '1') {
        putAction('/sys/user/changeWorkInfoTimer', { workInfoTimer: this.getCurrentTime() })
      } else {
        if (
          this.$refs.userForm.formData.userFile.userName &&
          this.$refs.userForm.formData.userFile.sex &&
          this.$refs.userForm.formData.userFile.bornDate &&
          this.$refs.userForm.formData.userFile.country &&
          this.$refs.userForm.formData.userFile.nation &&
          this.$refs.userForm.formData.userFile.phone &&
          this.$refs.userForm.formData.userFile.education &&
          this.$refs.userForm.formData.userFile.school &&
          this.$refs.userForm.formData.userFile.certificate &&
          this.$refs.userForm.formData.userFile.workExperience &&
          (this.$refs.userForm.formData.userFile.bankAccount || this.$refs.userForm.formData.userFile.alipayAccount)
        ) {
          let reg = /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/
          if (
            this.$refs.userForm.country != 'qt' &&
            this.$refs.userForm.country != 'gat' &&
            this.$refs.userForm.formData.userFile.contractType != '意向全职' &&
            this.$refs.userForm.formData.userFile.contractType != '兼职'
          ) {
            if (reg.test(this.$refs.userForm.formData.userFile.alipayAccount.replace(/\s*/g, ''))) {
              await putAction('/sys/user/changeWorkInfoTimer', {})
            }
          } else {
            await putAction('/sys/user/changeWorkInfoTimer', {})
          }
        }
      }
      setTimeout(() => (this.btnLoading = false), 2000)
      this.$refs.userForm.saveForm(async () => {
        // 先把新用户登录后要看人员须知那些隐藏掉吧，一上来就填信息就行
        putAction('/sys/user/changeNeedUserGuideFalse').then(async ({ success }) => {
          if (success) {
            // this.$message.success('您已完成新手指引')
            this.userInfo.userFile.needUserGuide = false
            Vue.ls.set(USER_INFO, this.userInfo, 7 * 24 * 60 * 60 * 1000)
            this.tab = '2'
          } else {
            // this.$message.error('新手指引完成失败了，请联系管理员')
          }
        })
      }, false)
    },
    handleMemberNotice() {
      this.memberNoticeVisible = false
      this.btnLoading = true
      setTimeout(() => (this.btnLoading = false), 2000)
      putAction('/sys/user/changeNeedUserGuideFalse').then(({ success }) => {
        if (success) {
          this.$message.success('您已完成新手指引')
          this.userInfo.userFile.needUserGuide = false
          Vue.ls.set(USER_INFO, this.userInfo, 7 * 24 * 60 * 60 * 1000)
        } else {
          this.$message.error('新手指引完成失败了，请联系管理员')
        }
      })
    },
    allTableConfirm() {
      this.confirmBtnDisable = false
    }
  }
}
</script>

<style lang="less">
.top-container {
  .ant-card-body {
    padding-top: 48px;
  }
}

.ant-card-head {
  border-bottom: 0px;
  padding-left: 48px;
  padding-right: 48px;
}

.ant-tabs-tab {
  font-size: 14px;
}
</style>

<style lang="less" scoped>
/deep/ .ant-card-head {
  border: none !important;
}
.page-header-wrapper-grid-content-main {
  width: 97%;
  height: 100%;
  min-height: 100%;
  transition: 0.3s;
  .account-center-avatarHolder {
    text-align: center;
    margin-bottom: 24px;
    & > .avatar {
      margin: 0 auto;
      width: 104px;
      height: 104px;
      margin-bottom: 20px;
      border-radius: 50%;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .username {
      color: rgba(0, 0, 0, 0.85);
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 4px;
    }
  }

  .account-center-detail {
    p {
      margin-bottom: 8px;
      padding-left: 26px;
      position: relative;
    }

    i {
      position: absolute;
      height: 14px;
      width: 14px;
      left: 0;
      top: 4px;
      background: url(https://gw.alipayobjects.com/zos/rmsportal/pBjWzVAHnOOtAUvZmZfy.svg);
    }

    .title {
      background-position: 0 0;
    }

    .group {
      background-position: 0 -22px;
    }

    .address {
      background-position: 0 -44px;
    }
  }

  .account-center-tags {
    .ant-tag {
      margin-bottom: 8px;
    }
  }

  .account-center-team {
    .members {
      a {
        display: block;
        margin: 12px 0;
        line-height: 24px;
        height: 24px;

        .member {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          line-height: 24px;
          max-width: 100px;
          vertical-align: top;
          margin-left: 12px;
          transition: all 0.3s;
          display: inline-block;
        }

        &:hover {
          span {
            color: #1890ff;
          }
        }
      }
    }
  }

  .tagsTitle,
  .teamTitle {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 12px;
  }
}
</style>
