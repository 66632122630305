var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "page-header-index-wide page-header-wrapper-grid-content-main",
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            { attrs: { md: 24, lg: 24 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "top-container",
                  staticStyle: { width: "100%" },
                  attrs: { bordered: false },
                },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 16, lg: 16 } },
                        [
                          _c(
                            "info-item",
                            {
                              attrs: {
                                labelWidth: _vm.labelWidth,
                                label: "昵称",
                              },
                            },
                            [_vm._v(_vm._s(_vm.userInfo.nickName))]
                          ),
                          _c(
                            "info-item",
                            {
                              attrs: {
                                labelWidth: _vm.labelWidth,
                                label: "邮箱",
                              },
                            },
                            [_vm._v(_vm._s(_vm.userInfo.email))]
                          ),
                          _c(
                            "info-item",
                            {
                              attrs: {
                                labelWidth: _vm.labelWidth,
                                label: "所属部门",
                              },
                            },
                            _vm._l(_vm.userInfo.departs, function (one) {
                              return _c("a-tag", { key: one.id }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(one.departName) +
                                    "\n                "
                                ),
                              ])
                            }),
                            1
                          ),
                          _c(
                            "info-item",
                            {
                              attrs: {
                                labelWidth: _vm.labelWidth,
                                label: "职位",
                              },
                            },
                            _vm._l(_vm.userInfo.positions, function (one) {
                              return _c("a-tag", { key: one.id }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(one.name) +
                                    "\n                "
                                ),
                              ])
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            { attrs: { md: 24, lg: 24 } },
            [
              _c(
                "a-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isDesktop(),
                      expression: "isDesktop()",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    bordered: false,
                    tabList: _vm.tabListNoTitle,
                    activeTabKey: _vm.noTitleKey,
                  },
                  on: {
                    tabChange: (key) => _vm.handleTabChange(key, "noTitleKey"),
                  },
                },
                [
                  _vm.noTitleKey === "rating"
                    ? _c("rating-page")
                    : _vm.noTitleKey === "settlement"
                    ? _c("settlement-page")
                    : _vm.noTitleKey === "user-file"
                    ? _c("user-file-page", {
                        ref: "userForm",
                        on: {
                          changeUserTab: _vm.changeUserTab,
                          upImg: _vm.upImg,
                          openCropper: _vm.openCropper,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          staticClass: "login-user",
          attrs: {
            keyboard: false,
            "mask-closable": false,
            closable: false,
            width: "700px",
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _vm.tab == "2"
                    ? _c(
                        "a-button",
                        { key: "back", on: { click: _vm.closeUserModal } },
                        [_vm._v("\n          关闭\n        ")]
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: { click: _vm.handleUserInfo },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.tab == "1" ? "下一步" : "保存") +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.userModalVisible,
            callback: function ($$v) {
              _vm.userModalVisible = $$v
            },
            expression: "userModalVisible",
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("\n        填写个人信息：\n        "),
            _c("span", { staticStyle: { color: "red", "font-size": "12px" } }, [
              _vm._v(
                "最迟需在第一次登录系统后的次日24点前填写完毕，否则无法正常进入系统。"
              ),
            ]),
          ]),
          _c("login-user-modal", {
            ref: "userForm",
            attrs: { tab: _vm.tab, userInfo: _vm.userInfo },
            on: {
              changeTabs: _vm.changeTabs,
              changeTab: _vm.changeTab,
              openCropper: _vm.openCropper,
              closeUserModal: _vm.closeUserModal,
            },
          }),
        ],
        1
      ),
      _c("user-cropper-model", {
        ref: "userCropperModel",
        on: { upImg: _vm.upImg },
      }),
      _c(
        "a-modal",
        {
          attrs: {
            keyboard: false,
            "mask-closable": false,
            closable: false,
            title: "请选择可工作日",
            width: "35%",
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: { type: "primary" },
                      on: { click: _vm.saveWork },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.workVisible,
            callback: function ($$v) {
              _vm.workVisible = $$v
            },
            expression: "workVisible",
          },
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "workForm",
                attrs: {
                  model: _vm.formData.userFile,
                  rules: _vm.validatorRules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { prop: "workDays", label: "通常每周可工作日" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        mode: "multiple",
                        placeholder: "通常每周可工作日",
                      },
                      on: { change: _vm.workDaysChange },
                      model: {
                        value: _vm.formData.userFile.workDays,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.userFile, "workDays", $$v)
                        },
                        expression: "formData.userFile.workDays",
                      },
                    },
                    [
                      _c(
                        "a-select-option",
                        { attrs: { value: 1, disabled: _vm.selectDis } },
                        [_vm._v("周一")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: 2, disabled: _vm.selectDis } },
                        [_vm._v("周二")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: 3, disabled: _vm.selectDis } },
                        [_vm._v("周三")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: 4, disabled: _vm.selectDis } },
                        [_vm._v("周四")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: 5, disabled: _vm.selectDis } },
                        [_vm._v("周五")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: 6, disabled: _vm.selectDis } },
                        [_vm._v("周六")]
                      ),
                      _c(
                        "a-select-option",
                        { attrs: { value: 7, disabled: _vm.selectDis } },
                        [_vm._v("周日")]
                      ),
                      _c("a-select-option", { attrs: { value: -1 } }, [
                        _vm._v("跳过中国法定节假日"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    prop: "workdayAvailableCount",
                    label: "工作日每天可安排数量",
                  },
                },
                [
                  _c("a-input-number", {
                    attrs: { min: 0 },
                    on: { change: _vm.workCountChange },
                    model: {
                      value: _vm.formData.userFile.workdayAvailableCount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData.userFile,
                          "workdayAvailableCount",
                          $$v
                        )
                      },
                      expression: "formData.userFile.workdayAvailableCount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            keyboard: false,
            "mask-closable": false,
            closable: false,
            title: "个人信息",
            width: "35%",
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: { type: "primary" },
                      on: { click: _vm.saveSelf },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.selfInfoShow,
            callback: function ($$v) {
              _vm.selfInfoShow = $$v
            },
            expression: "selfInfoShow",
          },
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "selfForm",
                attrs: {
                  model: _vm.formData.userFile,
                  rules: _vm.validatorSelfRules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    height: "20px",
                    "padding-bottom": "42px",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        position: "absolute",
                        "font-size": "12px",
                        top: "0",
                        color: "#aca9a9",
                      },
                    },
                    [_vm._v("下列信息仅BOSS及HR有权查看，请如实填写。")]
                  ),
                ]
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "姓名", prop: "userName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.formData.userFile.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.userFile, "userName", $$v)
                      },
                      expression: "formData.userFile.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "性别", prop: "sex" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.formData.userFile.sex,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.userFile, "sex", $$v)
                        },
                        expression: "formData.userFile.sex",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("男")]),
                      _c("a-radio", { attrs: { value: 0 } }, [_vm._v("女")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "出生日期", prop: "bornDate" } },
                [
                  _c("a-date-picker", {
                    attrs: { format: "YYYY-MM-DD" },
                    model: {
                      value: _vm.formData.userFile.bornDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.userFile, "bornDate", $$v)
                      },
                      expression: "formData.userFile.bornDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "国籍", prop: "country" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "国籍" },
                      model: {
                        value: _vm.formData.userFile.country,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.userFile, "country", $$v)
                        },
                        expression: "formData.userFile.country",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "中国" } }, [
                        _vm._v("中国"),
                      ]),
                      _c("a-select-option", { attrs: { value: "美国" } }, [
                        _vm._v("美国"),
                      ]),
                      _c("a-select-option", { attrs: { value: "英国" } }, [
                        _vm._v("英国"),
                      ]),
                      _c("a-select-option", { attrs: { value: "日本" } }, [
                        _vm._v("日本"),
                      ]),
                      _c("a-select-option", { attrs: { value: "韩国" } }, [
                        _vm._v("韩国"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "电话号码", prop: "phone" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入电话号码" },
                    model: {
                      value: _vm.formData.userFile.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.userFile, "phone", $$v)
                      },
                      expression: "formData.userFile.phone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }